import React from "react";

import HeaderImage from "../img/Header.jpg";

import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Header({ loading, setLoading, bigHeader }) {
  const [navigationOpened, setNavigationOpened] = React.useState(false);

  React.useEffect(() => {
    if (navigationOpened) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [navigationOpened]);

  return (
    <>
      <CSSTransition
        in={navigationOpened}
        unmountOnExit
        onExit={() => {}}
        onEnter={() => {}}
        timeout={500}
        classNames="nav-anim"
      >
        <div id="myNav" className="overlay open">
          <div className="container">
            <svg
              onClick={() => {
                setNavigationOpened(false);
              }}
              className="closebtn"
              fill="none"
              stroke="#fff"
              strokeWidth={3}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </div>
          <div className="overlay-content">
            <Link
              onClick={() => {
                setNavigationOpened(false);
              }}
              to="/"
            >
              Startseite
            </Link>
            <Link
              onClick={() => {
                setNavigationOpened(false);
                setLoading(true);
              }}
              to="/fahrzeuge"
            >
              Fahrzeuge
            </Link>
            <Link
              onClick={() => {
                setNavigationOpened(false);
                setLoading(true);
              }}
              to="/termine"
            >
              Termine
            </Link>
            <Link
              onClick={() => {
                setNavigationOpened(false);
                setLoading(true);
              }}
              to="/geschichte"
            >
              Geschichte
            </Link>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={loading}
        unmountOnExit
        onExit={() => {}}
        onEnter={() => {}}
        timeout={500}
        classNames="nav-anim"
      >
        <div className="loader">
          <div className="loader-object"></div>
        </div>
      </CSSTransition>
      <div className="container header">
        <div className="flex justify-between items-center w-full">
          <Link to="/">
            <div className="font-bold text-4xl">FFW Lederdorn</div>
          </Link>

          {/* <button
            className="bg-accent p-3"
            onClick={() => setNavigationOpened(true)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button> */}
        </div>

        {bigHeader && (
          <>
            <LazyLoadImage
              className="Header"
              draggable="false"
              src={HeaderImage}
            />
            <h1 className="HeaderText">
              Stütz&shy;punkt&shy;feuerwehr im Land&shy;kreis Cham.
            </h1>

            <div className="buttons">
              <Link draggable="false" className="button" to="/geschichte">
                Geschichte
              </Link>
              <Link draggable="false" className="button" to="/fahrzeuge">
                Fahrzeuge
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Header;
