import React from "react";
import Header from "../components/Header";

function Geschichte({ loading, setLoading }) {
    React.useEffect(() => {
        setLoading(false);
    }, []);
    return (
        <>
            <Header
                loading={loading}
                setLoading={setLoading}
                bigHeader={false}
            />
            <div className="App container">
                <h1 className="Title">Geschichte:</h1>
                <div className="geschichte">
                    <p>
                        Der Weg von der Pflicht zur Freiwilligkeit, von der
                        Dorfzur Stützpunktfeuerwehr. Mehr als 100 Jahre sind, so
                        sagt man, im großen Zeitablauf nur ein Wimpernschlag, in
                        der Geschichte eines Vereins ist es eine lange Epoche,
                        in der aus kleinen Anfängen eine Gemeinschaft wächst auf
                        deren Lebenswerk immer wieder nachfolgende Generationen
                        bauen. Ihren Vertretern steht es dabei gut an, wenn sie
                        sich an die Mühsal und Schwernisse erinnern lassen, mit
                        denen die Gründerväter einmal starten mussten. Jene
                        Männer, die vor einem Jahrhundert zur Gründung einer
                        Freiwilligen Feuerwehr in Lederdorn antraten, mussten
                        erst einmal Wege roden, sie von Hindernissen freimachen
                        mit denen menschliches Denken und der Zeitgeist
                        Neuerungen nicht selten hemmend begleiteten. Epochen des
                        Stillstandes, vielleicht einmal eines Rückschrittes,
                        sind dabei nicht auszuschließen, blieben auch der
                        Geschichte dieser Wehr nicht erspart. In den
                        Chronikblättern sind sie als Zeitzeugen zurückgeblieben,
                        markieren den konstanten Weg einer kleinen Dorfzur
                        Stützpunktfeuerwehr. Wie klein die Schritte waren, die
                        den Fortschritt bezeugen, macht schon allein der
                        Zeitraum, der zwischen den beiden Stationen der Gründung
                        und heute liegt, klar. In das „Stammbuch“ der Wehr
                        könnten eigentlich zwei Geburtsdaten eingetragen werden,
                        einmal das für die Gründung der aktiven Mannschaft aus
                        dem Jahre 1897, aber auch jenes der eigentlichen
                        Vereinsgründung, die mit dem 15. Januar 1898, der
                        Vorstandswahl, datiert ist. Eine nicht unbedeutende
                        Statusveränderung einer Gemeinschaft wurde damals mit
                        dem Wandel von der Pflicht in die Freiwilligkeit
                        vollzogen. 1897: Gründung der Freiwilligen Feuerwehr
                        Lederdorn. 27 Freiwillige bildeten die
                        Gründermannschaft. Im Protokollbuch aus dem Jahre 1897
                        ist über dieses wichtige Ereignis zu lesen: „Auf heute
                        Mittag, 1 Uhr, wurde eine Pflichtfeuerwehrübung
                        anberaumt. Nach derselben versammelten sich die
                        pflichtigen Männer der Gemeinde Lederdorn und der
                        Ortschaft Meinzing in der Behausung des Bürgermeisters,
                        wo auf Einladung der Bezirksfeuerwehrvertreter Kuchler
                        aus Kötzting erschien, derselbe die Vorzüge und
                        Pflichten einer freiwilligen Feuerwehr erörterte, worauf
                        beschlossen wurde, in der Gemeinde Lederdorn eine
                        freiwillige Feuerwehr zu gründen.“ 27 Männer
                        unterzeichneten die Gründungsurkunde, womit in Lederdorn
                        der bestehenden Pflichtfeuerwehr, zu der Männer
                        bestimmter Altersgruppen verpflichtet wurden um mit oft
                        noch sehr primitiven Mitteln, wie Löscheimer und
                        Handpumpen, den Mitmenschen in einem Brandfall zu Hilfe
                        zu kommen, bei Einsätzen jetzt auch eine Gruppe
                        Freiwilliger gegenüberstand. Der Bauer Gregor Altmann
                        wurde zum Kommandanten gewählt, als Zugführer des
                        Steigerzuges, der aus acht Mann bestand, der Söldner
                        Josef Schmid, als Spritzenzugführer der Bauer Josef
                        Wensauer, als Ordnungszugführer der Gastwirtssohn Georg
                        Schmucker, als Adjutant der Häusler Josef Schwab, als
                        Requisitenmeister Andreas Irrgang, als Schriftführer und
                        Kassier Georg Altmann. Die Unterzeichner waren der
                        damalige Bürgermeister Johann Heigl, Xaver Schmucker,
                        Josef Wensauer, Josef Heigl, Max Altmann, Michl
                        Bergbauer, Joseph Weißthanner, Andreas Heigl, Georg
                        Altmann, Josef Eder, Josef Schwab, Alois Altmann, Georg
                        Peintinger, Alois Irrgang, Anton Wanninger, Andreas
                        Irrgang, Johann Bügerl, Alois Saurer, Andreas
                        Schedlbauer, Franz Schmucker, Anton Sauer, Georg
                        Schmucker, Joseph Bräu, Michl Altmann, Franz Martin,
                        Gregor Altmann, Johann Pongratz, Joseph Schmid. Rund
                        zwei Monate nach der Gründung begann die Wehr bereits
                        mit einer entsprechenden „Aufrüstung“. Bei der Firma
                        Ludwig, Bayreuth, die noch in vielen folgenden Jahren
                        als Lieferant auftrat, wurden Filzhelme, das Stück zu
                        vier Mark, mit der Kennzeichnung „L“ versehen, ein
                        Signalhorn für neun Mark und entsprechende Gradsterne
                        bestellt, mit dem Hinweis an den Lieferanten, dass die
                        Bezahlung nicht gleich erfolgen könne, da in der Kasse
                        noch die entsprechenden Mittel fehlten.
                    </p>
                    <ul>
                        <li>
                            1898: Gründung des Vereins am 15. Januar mit den
                            ersten Vorstandswahlen. Unter das Dach eines Vereins
                            wurde die Mannschaft der Freiwilligen Feuerwehr
                            Lederdorn in der Generalversammlung gestellt, Die
                            restaurierte Gründungsfahne von 1898 und die neue
                            Fahne von 1964 in der der Ökonom Anton Sauer aus
                            Meinzing zum Vorstand, der Schneidermeister Xaver
                            Schmucker zum Vereinsdiener gewählt wurden. Die
                            leere Vereinskasse bedurfte einer Auffüllung,
                            weshalb noch im gleichen Jahr zwei Bälle abgehalten
                            wurden, zu denen man mit der Gemeindebevölkerung
                            auch schon die Nachbarwehren einlud. Über das
                            Guthaben des Vereins ist zu dieser Zeit nichts
                            bekannt. Die erste Vereinsfahne wird angeschafft und
                            geweiht. Fahnenmutter ist Theresia Meier.
                        </li>
                        <li>
                            1973: Einmal ein Novum im Inspektionsbereich
                            Kötzting. Bislang legten 21 Gruppen die
                            Leistungsprüfung ab. 14 Feuerwehranwärter der
                            Freiwilligen Feuerwehr Lederdorn bestanden 1973
                            erfolgreich die Leistungsprüfung. Damit schuf die
                            Wehr ein Novum in der Feuerwehrgeschichte der
                            Inspektion, weil es die erste Prüfung dieser Art
                            war.
                        </li>
                        <li>
                            1982: Die FF Lederdorn wird Stützpunkt-Feuerwehr
                        </li>
                        <li>
                            1985: 1. Kommandant Johann Weber wird fachbezogener
                            Kreisbrandmeister für das Funkwesen im Landkreis
                            Cham.
                        </li>
                        <li>
                            1999: 1. Kommandant und Kreisbrandmeister Johann
                            Weber wird am 1. November zum Kreisbrandrat für den
                            Landkreis Cham gewählt.
                        </li>
                        <li>
                            2001: Der langjährige Jugendwart und Kommandant
                            Andreas Bergbauer wird am 1. Januar zum
                            Kreisbrandmeister für den KBM-Bereich Miltach
                            bestimmt.
                        </li>
                    </ul>
                    <hr></hr>
                    <h1>Kommandanten</h1>
                    <ul>
                        <li>1897-1900: Gregor Altmann, Ökonom aus Lederdorn</li>
                        <li>1900-1905: Josef Schwab, Häusler aus Lederdorn</li>
                        <li>1906-1909: Gregor Altmann, Ökonom aus Lederdorn</li>
                        <li>
                            1910-1914: Franz Schmucker, Ökonom aus Lederdorn
                        </li>
                        <li>1915-1920: nicht bekannt</li>
                        <li>1921-1924: Michl Fischer, Söldner aus Lederdorn</li>
                        <li>
                            1925-1929: Xaver Mückl, Häusler aus Lederdorn/Grüben
                        </li>
                        <li>
                            1930-1933: Xaver Bräu, Steinmetzmeister aus
                            Lederdorn
                        </li>
                        <li>1934-1946: nicht bekannt</li>
                        <li>
                            1946-1948: Josef Pongratz, Söldner aus Lederdorn
                        </li>
                        <li>1948-1949: Anton Irrgang, Söldner aus Lederdorn</li>
                        <li>
                            1949-1950: Josef Fischer, Arbeiter aus Lederdorn
                        </li>
                        <li>
                            1950-1953: Ludwig Altmann, Kraftfahrer aus Lederdorn
                        </li>
                        <li>
                            1953-1955: Karl Miethaner, Schuster aus Lederdorn
                        </li>
                        <li>
                            1956-1960: Vitus Irrgang, Schreinermeister aus
                            Lederdorn
                        </li>
                        <li>
                            1961-1972: Johann Pongratz, Zimmermann aus
                            Lederdorn/Moos
                        </li>
                        <li>1972-1975: Xaver Singer, Arbeiter aus Lederdorn</li>
                        <li>
                            1975-1979: Xaver Weber, Zimmerermeister aus
                            Lederdorn/Moos
                        </li>
                        <li>
                            1979-1980: Konrad Bergbauer, Maurer aus Lederdorn
                        </li>
                        <li>
                            1980-1999: Johann Weber,
                            Radio-/Fernsehtechnikermeister aus Lederdorn/Moos
                        </li>
                        <li>
                            1999-2001: Andreas Bergbauer,
                            Energieanlagenelektroniker aus Lederdorn
                        </li>
                        <li>
                            2001-2013: Günther Hartl, Kfz-Mechaniker aus
                            Lederdorn
                        </li>
                        <li>2013-2019: Matthias Klein, BBA aus Lederdorn</li>
                        <li>seit 2019: Josef Weißthanner jr.</li>
                    </ul>
                    <hr></hr>
                    <h1>Vorsitzende</h1>
                    <ul>
                        <li>
                            1898-1901: Anton Sauer, Bauer aus Lederdorn/Meinzing
                        </li>
                        <li>1901-1906: Gregor Altmann, Ökonom aus Lederdorn</li>
                        <li>
                            1907-1909: Johann Iglhaut, Söldner aus Lederdorn
                        </li>
                        <li>1910-1914: Josef Bräu, Ökonom aus Lederdorn</li>
                        <li>1915-1921: nicht bekannt</li>
                        <li>1921-1924: Franz Martin, Häusler aus Lederdorn</li>
                        <li>1925-1929: Max Altmann, Schneider aus Lederdorn</li>
                        <li>
                            1930-1933: Stephan Altmann, Vorarbeiter aus
                            Lederdorn
                        </li>
                        <li>
                            1934-1945: Josef Bergbauer, Landwirt aus Lederdorn
                        </li>
                        <li>1946-1949: Karl Schmid, Landwirt aus Lederdorn</li>
                        <li>
                            1950-1955: Josef Fischer, Angestellter aus Lederdorn
                        </li>
                        <li>
                            1956-1957: Josef Lommer, Zimmerermeister aus
                            Lederdorn/Moos
                        </li>
                        <li>
                            1958-1972: Xaver Peintinger, Postbeamter aus
                            Lederdorn
                        </li>
                        <li>1972-1975: Alois Weber, Arbeiter aus Lederdorn</li>
                        <li>
                            1975-1983: Johann Pongratz, Zimmermann aus
                            Lederdorn/Moos
                        </li>
                        <li>1983-1989: Alois Eder, Schneider aus Lederdorn</li>
                        <li>
                            1989-2000: Josef Sauer, Landwirt aus
                            Lederdorn/Meinzing
                        </li>
                        <li>
                            2000-2019: Xaver Heigl, Beamter T-Com aus Lederdorn
                        </li>
                        <li>
                            seit 2019: Markus Weber, Architekt aus Lederdorn
                        </li>
                    </ul>
                    <hr></hr>
                    <h1>Feste & Jubiläen</h1>
                    <p>
                        Die Zeitrechnung für die Feste wurden auf das Datum der
                        Vereinsgründung bezogen: Ende 1897 wurde die aktive
                        Mannschaft gegründet und am 15. Januar 1898 der Verein.
                        Darum wurden die Feste jeweils mit einem Jahr
                        Zeitversatz gefeiert.
                    </p>
                    <ul>
                        <li>
                            26. Mai 1957: 60-jähriges Gründungsfest – das erste
                            große Fest der Wehr. Festbraut war Maria Gigler, zum
                            Festgefolge gehörten Kathi Klein, Christa Schmucker,
                            Gerdi Schmid und Roswitha Pongratz. Patenverein ist
                            die FF Bärndorf. Das Fest wurde von Landrat Nemmer
                            beschirmt. Den Festgottesdienst zelebrierten Pfarrer
                            Fischl und Jugendpräses Maier. Die noch lebenden
                            Gründungsmitglieder Michl Bergbauer und Josef
                            Weißthanner wurden besonders geehrt.
                        </li>
                        <li>
                            9. August 1964: Fahnenweihe: Die „neue“ Fahne
                            erhielt den kirchlichen Segen. Den Auftrag für die
                            Fertigung erhielt die Fahnenstickerei Kössinger in
                            Schierling. Fahnenmutter war Irene Aschinger. 49
                            Vereine folgten der Einladung und feierten mit der
                            Wehr. Handspritze, Baujahr 1839 12. bis 15. August
                            1988: 90-jähriges Gründungsfest: Schirmherr war
                            wiederum Albert Mayer. Pfarrer Karl Schmid
                            zelebrierte den Festgottesdienst. Zu den zahlreichen
                            Vereinsdelegationen, die als Gratulanten erschienen,
                            zählte auch eine Abordnung der Feuerwehr Kirrlach
                            aus Baden, zum Festgefolge mit zahlreichen
                            Ehrengästen Fahnenmutter Irene Aschinger und die
                            ehemaligen Festbräute Maria Altmann (Gigler) und
                            Erika Bohmann (Saurer). 9. bis 11. Juli 1993: Feier
                            des 20-jährigen Bestehens der Jugendfeuerwehr.
                            Festbraut war Heidi Weber, Schirmherr Vitus Irrgang,
                            Ehrenschirmherr Albert Mayer. Die FF Kirrlach nahm
                            mit ca. 50 Mann teil. 17. bis 20. Juli 1998:
                            100-jähriges Gründungsfest Schirmherr war Xaver
                            Weber und Festmutter Imelda Weber. Über 80 Vereine
                            inkl. der FF Kirrlach feierten an 4 Tagen mit FF
                            Lederdorn. Für diesen Anlass wurden beide
                            Vereinsfahnen restauriert. Als Geschenk von der
                            Festmutter gab es ein neues Vereinstaferl.
                            Patenverein war die FF Runding.
                        </li>
                        <li>
                            Jubiläumsfeierlichkeiten anlässlich des 30-jährigen
                            Bestehens der Jugendfeuerwehr mit Segnung eines
                            eigenen Jugendwimpels.
                        </li>
                        <li>
                            28.09.2008: Das 110-jährige Gründungsfest wurde im
                            kleinen Rahmen gefeiert.
                        </li>
                        <li>
                            7-9.09.2018: Segnung des neuen
                            Hilfeleistungslöschgruppenfahrzeugs, der
                            Gerätehauserweiterung und 120-Jahre FF-Lederdorn
                        </li>
                    </ul>
                    <hr></hr>
                    <h1>Ausstattung</h1>
                    <ul>
                        <li>um 1900: Eine Handspritze wird angeschafft</li>
                        <li>
                            5. August 1957: Die neue Motorspritze (TS 8) wurde
                            an die Feuerwehr übergeben, die Anschaffungskosten
                            betrugen 3.400 DM. Die bisherige Spritze war trotz
                            mehrmaliger Reparaturen nicht mehr zuverlässig.
                            Damit war die Einsatzbereitschaft der Wehr wieder
                            gesichert.
                        </li>
                        <li>
                            August 1958: Ein Spezialanhänger wurde angeschafft.
                            Ein Großteil der Kosten wurde durch Spenden der
                            Bevölkerung aufgebracht.
                        </li>
                        <li>
                            3. Juni 1962: Eine Handfeuersirene wurde
                            angeschafft; mit der Alarmierung mittels
                            Kirchenglocken hatte man beim letzten Brand
                            schlechte Erfahrungen gemacht.
                        </li>
                        <li>
                            1965: Schutzanzüge und Schlauchmaterial wurden
                            angeschafft. Die Feuerwehr besaß inzwischen über 500
                            m Schläuche – somit konnte jedes Gebäude in
                            Lederdorn erreicht werden.
                        </li>
                        <li>
                            17. November 1968: Weihe des
                            Tragkraftspritzenfahrzeugs (Ford Transit) durch
                            Pfarrer Fischl
                        </li>
                        <li>
                            10. bis 12. Juni 1983: Das neue Fahrzeug LF8-2 mit
                            Rettungssatz wurde eingeweiht, Schirmherr war Albert
                            Mayer.
                        </li>
                        <li>
                            1984: Inbetriebnahme eines gebrauchten
                            Tanklöschfahrzeugs TLF 16-25, das von der FF
                            Kirrlach aus Eigenmitteln der FF Lederdorn erworben
                            wurde. Das Fahrzeug ist 20 Jahre alt und wurde in
                            ungezählten Stunden Eigenleistung wieder „top“
                            hergerichtet.
                        </li>
                        <li>
                            1986: Anschaffung von Schaumund Wasserwerfer auf
                            Einachsanhänger Typ „ALCO“
                        </li>
                        <li>
                            1989: Anschaffung von Hebekissen, Rettungszylinder,
                            Heuwehrgerät, Greifzug
                        </li>
                        <li>
                            9. bis 11. Juli 1993: Weihe des TLF 16/25, die
                            Segnung nahm Pfarrer Karl Schmid vor.
                        </li>
                        <li>
                            2000: Anschaffung eines Mehrzweckfahrzeuges aus
                            Eigenmitteln, welches in unzähligen Stunden umgebaut
                            und aufbereitet wurde.
                        </li>
                        <li>
                            2005: Eine Wärmebildkamera wurde aus Eigenmitteln
                            angeschafft.
                        </li>
                        <li>
                            2011: Segnung der neuen Ziegler Tragkraftspritze
                            durch Prälat Dr. Döring
                        </li>
                        <li>
                            2017: Anschaffung eines neuen
                            Hilfeleistungslöschgruppenfahrzeugs.
                        </li>
                    </ul>
                    <hr></hr>
                    <h1>Feuerwehrhaus</h1>
                    <ul>
                        <li>
                            Das erste Feuerwehrhaus befand sich in der heutigen
                            „alten Dorfstraße“ gegenüber dem „Ertl-Weiher“.
                            Später wurde dort das Haus von Josef Fischer („Lenzn
                            Sepp“) errichtet. Es war ein einfacher gemauerter
                            Schuppen, in dem gerade die Handspritze Platz hatte.
                        </li>
                        <li>
                            1934 bis 1935: Ein neues Feuerwehrhaus mit
                            Schlauchturm wurde gebaut (Maurerarbeiten Xaver
                            Weber, Dachstuhl und Turm Josef Lommer).
                        </li>
                        <li>
                            6. bis 8. August 1976: Einweihung des
                            Gemeinschaftshauses, in dem jetzt auch die Feuerwehr
                            ihr neues Domizil hat. Pfarrer Kiermeier und Pfarrer
                            Fischl nahmen die Segnung des neuen Hauses vor, bei
                            dessen Bezug aber noch der Wunsch nach einem neuen
                            Fahrzeug offen war.
                        </li>
                        <li>
                            1996: Schaffung eines Jugendraumes für die
                            Feuerwehrjugend
                        </li>
                        <li>
                            Im Jahr 1999 wird in Eigenregie und aus Eigenmitteln
                            ein Schulungsraum und eine Küche ausgebaut.
                        </li>
                    </ul>
                    <hr></hr>
                    <h1>Auszeichnungen</h1>
                    <ul>
                        <li>
                            Johann Weber, KBM und KBR: Deutsches
                            Feuerwehr-Ehrenkreuz in Silber am 31.03.1990,
                            Deutsche Ehrennadel der Jugend in Silber am
                            20.04.1990, Steckkreuz am 25.09.1995, Bayerisches
                            Feuerwehr Ehrenkreuz in Silber am 07.05.1999,
                            Bayerische Ehrennadel der Jugend in Silber am
                            13.09.2002, Deutsches Feuerwehr-Ehrenkreuz in Gold
                            am 26.03.2004, Silberne Ehrennadel DFV am
                            24.10.2008, Bayerisches Feuerwehr Ehrenkreuz in Gold
                            am 27.11.2011 und Bundesverdienstkreuz am Bande am
                            15.09.2012
                        </li>
                        <li>
                            Andreas Bergbauer, KBM: Bayerische Ehrennadel der
                            Jugend in Silber am 16.05.1998 und Bayerisches
                            Feuerwehr Ehrenkreuz in Silber am 15.03.2013
                        </li>
                        <li>
                            Günther Hartl, Kommandant: Bayerisches Feuerwehr
                            Ehrenkreuz in Silber am 22.02.2008
                        </li>
                        <li>
                            Xaver Heigl, Vorsitzender: Bayerische Feuerwehr
                            Ehrenmedaille am 22.02.2008
                        </li>
                        <li>
                            Rudolf Böhm: Feuerwehr-Ehrenkreuz des KFV Cham in
                            Silber am 10.11.2012
                        </li>
                        <li>
                            Karl-Heinz Würges: Feuerwehr-Ehrenkreuz des KFV Cham
                            in Silber am 20.09.2013
                        </li>
                    </ul>
                    <hr></hr>
                    <h1>Brände & Einsätze</h1>
                    <ul>
                        <li>
                            15.04.1899: Erster beurkundeter Einsatz der FF
                            Lederdorn beim Großbrand in Kötzting. Am oberen
                            Markt wurden 27 Gebäude und 7 Stallungen und Stadel
                            vernichtet. Zwei Bäckerburschen starben in den
                            Flammen. 18 Feuerwehren waren zur Brandbekämpfung
                            eingesetzt. Ein Dankesschreiben des Magistrats
                            belegt dies.
                        </li>
                        <li>
                            08.05.1901: Einsatz beim nächtlichen Stadelbrand bei
                            Klimmer in Gradis
                        </li>
                        <li>
                            20.11.1925: Brand bei den Bauern Vogl und
                            Schedlbauer in Meinzing. Durch das Feuer wurde die
                            Scheune des Vogl und ein Holzschuppen des
                            Schedlbauer vernichtet.
                        </li>
                        <li>
                            11.11.1944: Durch einen Bombeneinschlag brannten
                            Haus und Scheune von Hans Pongratz, Moos, nieder. Es
                            handelte sich dabei um einen Fehlabwurf. Die
                            Brandbomben waren für den Bahnhof Miltach bestimmt.
                            Durch den beherzten Einsatz konnten die Bewohner und
                            das Vieh gerettet werden.
                        </li>
                        <li>
                            30.01.1954: Brand in Lederdorn, die Scheunen von
                            Ulrich Bremm und Michael Schedlbauer brannten
                            nieder. Durch die eisige Kälte wurde der
                            Löscheinsatz extrem behindert. Immer wieder fror das
                            Wasser in den Schläuchen ein. Durch den mutigen
                            Einsatz der Helfer, die das Wasser mit Eimern zum
                            Brandherd transportieren, konnte ein weiteres
                            Ausbreiten des Feuers bis zum Eintreffen der
                            Nachbarwehren und dem Aufbau einer
                            Versorgungsleitung vom „Veichten-Weiher“ verhindert
                            und die Wohnhäuser gerettet werden.
                        </li>
                        <li>
                            25.06.1954: Löscheinsatz beim Brand der Schreinerei
                            Stoiber in Kolmberg
                        </li>
                        <li>1961: Brand bei Paul Raab in Kolmberg</li>
                        <li>10.06.1967: Brand bei Ignaz Raab in Plarnhof</li>
                        <li>
                            31.10.1969: Brand des Sägemehlsilos der Fa. Stoiber
                            in Kolmberg
                        </li>
                        <li>
                            12.01.1973: Brand in der Schreinerei Greil,
                            Lederdorn
                        </li>
                        <li>16.08.1975: Brand in Lederdorn</li>
                        <li>30.05.1976: Brandeinsatz in Lederdorn</li>
                        <li>
                            29.12.1977: Scheunenbrand bei Albert Meier, Kolmberg
                        </li>
                        <li>10.04.1978: Einsatz beim Brand in Neukolmberg</li>
                        <li>30.06.1978: Brand in der Breitensteinmühle</li>
                        <li>
                            03.03.1979: Brand in der Bekleidungsfirma Ignaz Raab
                        </li>
                        <li>06.06.1979: Blitzschlag in Bärndorf</li>
                        <li>
                            05.08.1980: Brand in Roßberg, ein Wirtschaftsgebäude
                            vernichtet
                        </li>
                        <li>20.03.1981: Brandeinsatz in Kettersdorf</li>
                        <li>09.06.1981: Scheunenbrand in Kreuzbach</li>
                        <li>
                            22.07.1981: Technische Hilfeleistung beim Hochwasser
                            in Chamerau und Blaibach
                        </li>
                        <li>05.08.1981: Scheunenbrand in Roßberg</li>
                        <li>
                            18.08.1985: Feuer vernichtet Sägewerk „Steinmühle“
                        </li>
                        <li>
                            08.12.1985: Scheune, Stallung und Wohnhaus des
                            Anwesens Haberl werden beim Brand zerstört.
                        </li>
                        <li>
                            28.02.1990: Schuppenbrand bei Anna Iglhaut in
                            Lederdorn
                        </li>
                        <li>28.03.1990: Brand bei Franz Götz in Lederdorn</li>
                        <li>
                            21.08.1992: Brand in Lederdorn. Rund 100
                            Feuerwehrmänner verhindern durch beherzten wie
                            routinierten Einsatz einen Großbrand
                        </li>
                        <li>
                            03.01.1993: Brandeinsatz in Lederdorn, bei Hartl in
                            der Hauptstraße
                        </li>
                        <li>
                            24.08.2000: Brand beim Anwesen Hartmann, Untere
                            Bachstraße, Alarmstufe 2
                        </li>
                    </ul>
                    <hr></hr>
                    <h1>Jugendfeuerwehr</h1>
                    <p>
                        Die Jugendfeuerwehr wurde am 3. Februar 1973 gegründet.
                    </p>
                    <ul>
                        <li>
                            Im Februar 1973 legte diese erste Jugendgruppe im
                            ehemaligen Altlandkreis Kötzting die
                            Leistungsprüfung mit Erfolg ab.
                        </li>
                        <li>
                            Anlässlich der Weihe des TLF16/15 und dem
                            20-jährigen Bestehen der Jugendfeuerwehr,
                            organisierte die Jugendgruppe am 10. Juli 1993
                            Feuerwehrjugendspiele, bei denen insgesamt 43
                            Gruppen teilnahmen.
                        </li>
                        <li>
                            Beim 100-jährigen Gründungsfest 1998 durfte die
                            Jugendfeuerwehr auf 25 Jahre erfolgreiche
                            Nachwuchsarbeit zurückblicken – seit dem Bestehen
                            1973 wurden insgesamt 21 Leistungsprüfungen
                            abgelegt.
                        </li>
                        <li>
                            17. Mai 2003: Bei den Feierlichkeiten zum
                            30-jährigen Bestehen wurde ein Jugendwimpel geweiht.
                        </li>
                        <li>
                            Am 17. August 2013 konnten in Lederdorn 40 Jahre
                            Jugendfeuerwehr gefeiert werden.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Geschichte;
