import React from "react";

import HLF from "../img/hlf.jpg";
import TLF from "../img/tlf.jpg";
import MZF from "../img/mzf_new.JPG";
import SWF from "../img/SWF.jpg";

import Header from "../components/Header";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Fahrzeuge({ loading, setLoading }) {
  React.useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <Header loading={loading} setLoading={setLoading} bigHeader={false} />
      <div className="App container">
        <h1 className="Title">Fahrzeuge:</h1>
        <h2 className="Subtitle">HLF 10</h2>
        <LazyLoadImage
          alt={"HLF 10"}
          className="CarPicture"
          draggable="false"
          src={HLF}
        />
        <ul className="CarInfo">
          <li>Hilfeleistungslöschgruppenfahrzeug</li>
          <li>Baujahr: 2017</li>
        </ul>
        <hr />
        <h2 className="Subtitle">TLF 16-25 </h2>
        <LazyLoadImage
          src={TLF}
          alt="TLF 16-25"
          draggable="false"
          className="CarPicture"
        />
        <ul className="CarInfo">
          <li>Tanklöschfahrzeug</li>
          <li>Baujahr: 1993</li>
        </ul>
        <hr />
        <h2 className="Subtitle">MZF</h2>
        <LazyLoadImage
          src={MZF}
          alt="MZF"
          draggable="false"
          className="CarPicture"
        />
        <ul className="CarInfo">
          <li>Mehrzweckfahrzeug</li>
        </ul>
        <hr />
        <h2 className="Subtitle">SWF</h2>
        <LazyLoadImage
          src={SWF}
          draggable="false"
          alt="SWF"
          className="CarPicture"
        />
        <ul className="CarInfo">
          <li>Schaum-/Wasserwerfer Type “Alco” </li>
        </ul>
      </div>
    </>
  );
}

export default Fahrzeuge;
