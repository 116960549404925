import React from "react";
import Header from "../components/Header";

function Termine({ loading, setLoading }) {
    const [content, setContent] = React.useState(null);

    React.useEffect(() => {
        fetch(
            `https://cms.feuerwehr-lederdorn.de/ghost/api/v3/content/pages/?key=3bf0c20bdffbd19901f3d6d9b6`
        )
            .then((res) => res.json())
            .then((res) => {
                if (res) {
                    setLoading(false);
                    setContent(res);
                    res.pages.forEach((page) => {
                        if (page.title === "Termine") {
                            setContent(page);
                            console.log(page);
                        }
                    });
                }
            });
    }, []);

    return (
        <>
            <Header
                loading={loading}
                setLoading={setLoading}
                bigHeader={false}
            />
            <div className="App container">
                <h1 className="Title">Termine:</h1>

                {content && (
                    <div
                        dangerouslySetInnerHTML={{ __html: content.html }}
                    ></div>
                )}
            </div>
        </>
    );
}

export default Termine;
