import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
} from "react-router-dom";
import Header from "../components/Header";

function Article({ history, location, match, loading, setLoading }) {
    const [article, setArticle] = React.useState(null);

    React.useEffect(() => {}, []);

    return (
        <>
            <Header
                loading={loading}
                setLoading={setLoading}
                bigHeader={false}
            />
            <div className="App container">
                {article && (
                    <article>
                        <h2 className="LatestTitle">{article.title}</h2>
                        {article.feature_image && (
                            <img
                                draggable="false"
                                className="LatestPicture"
                                src={article.feature_image.replace(
                                    "http://localhost:2368/",
                                    "https://cms.feuerwehr-lederdorn.de/"
                                )}
                                alt=""
                            ></img>
                        )}
                        <div className="meta">
                            {" "}
                            {article.reading_time && (
                                <span>
                                    {article.reading_time} Minute
                                    {article.reading_time > 1 && (
                                        <span>n</span>
                                    )}{" "}
                                    Lesezeit
                                </span>
                            )}
                        </div>
                        <p
                            className="LatestText"
                            dangerouslySetInnerHTML={{ __html: article.html }}
                        ></p>
                    </article>
                )}
            </div>
        </>
    );
}

export default Article;
