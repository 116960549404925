import React from "react";

import { Link } from "react-router-dom";
import Header from "../components/Header";
import Image from "../img/DSC_0174.jpg";
import Kinderfeuerwehr from "../img/kfw.jpg";
import Uebung from "../img/DSC_0027-1.jpg";

function Home({ loading, setLoading }) {
  const [articles, setArticles] = React.useState([]);
  const [covidData, setCovidData] = React.useState(null);
  const [covidIncidenceChange, setCovidIncidenceChange] = React.useState(0);

  return (
    <div className="App">
      <Header loading={loading} setLoading={setLoading} bigHeader={true} />

      <div className="container">
        <h2 className="Title">Jugendfeuerwehr</h2>
        <p>Die Jugendfeuerwehr wurde am 3. Februar 1973 gegründet.</p>
        <ul>
          <li>
            Im Februar 1973 legte diese erste Jugendgruppe im ehemaligen
            Altlandkreis Kötzting die Leistungsprüfung mit Erfolg ab.
          </li>
          <li>
            Anlässlich der Weihe des TLF16/15 und dem 20-jährigen Bestehen der
            Jugendfeuerwehr, organisierte die Jugendgruppe am 10. Juli 1993
            Feuerwehrjugendspiele, bei denen insgesamt 43 Gruppen teilnahmen.
          </li>
          <li>
            Beim 100-jährigen Gründungsfest 1998 durfte die Jugendfeuerwehr auf
            25 Jahre erfolgreiche Nachwuchsarbeit zurückblicken – seit dem
            Bestehen 1973 wurden insgesamt 21 Leistungsprüfungen abgelegt.
          </li>
          <li>
            17. Mai 2003: Bei den Feierlichkeiten zum 30-jährigen Bestehen wurde
            ein Jugendwimpel geweiht.
          </li>
          <li>
            Am 17. August 2013 konnten in Lederdorn 40 Jahre Jugendfeuerwehr
            gefeiert werden.
          </li>
        </ul>
        <hr />
        <p className="Text">
          Zuletzt fand die alljährliche Jugendgemeinschaftsübung des KBM-Bereichs Miltach bei uns in Lederdorn statt. Hierbei durften 13 Jugendfeuerwehren einen Löschangriff beim Anwesen Eder aufbauen. Der Erstangriff erfolgte im Wald durch unsere Jugendgruppe, welcher durch eine Löschwasserleitung zur Wasserzysterne am Fußballplatz unterstützt wurde. Ebenso wurde ein zweiter Löschangriff bei der Scheune, aufgrund einer Rauchentwicklung, aufgebaut mit einer Wasserversorgung vom dortigen Weiher. 
        </p>
        <img className="Picture col-span-2 row-span-2" src={Uebung} alt=""></img>
        
        <h2 className="Title">Kinderfeuerwehr</h2>
        <p className="Text">
          Die Kinderfeuerwehr ist ein Angebot für Kinder im Alter von 6 bis 12
          Jahren. Sie soll den Kindern die Möglichkeit geben, spielerisch die
          Feuerwehr kennenzulernen und erste Erfahrungen zu sammeln. Die
          Kinderfeuerwehr ist eine eigenständige Abteilung innerhalb der
          Feuerwehr und wird von speziell ausgebildeten Betreuern geleitet.
        </p>

        <p className="Text">
          Die Kinderfeuerwehr wurde im Jahr 2022 gegründet:
        </p>
        <a
          target="_blank"
          className="button mb mt-0"
          href="https://www.mittelbayerische.de/archiv/1/lederdorn-hat-jetzt-eine-kinderfeuerwehr-es-fehlte-nur-noch-ein-gruppenname-11861591"
          rel="noreferrer"
        >
          Mehr erfahren
        </a>

        <img className="Picture" src={Kinderfeuerwehr} alt=""></img>

        {articles.map((article, i) => {
          return (
            <>
              <article>
                <h2 className="LatestTitle">{article.title}</h2>
                {article.feature_image && (
                  <img
                    draggable="false"
                    className="LatestPicture"
                    src={article.feature_image.replace(
                      "http://localhost:2368/",
                      "https://cms.feuerwehr-lederdorn.de/"
                    )}
                    alt=""
                  ></img>
                )}
                <div className="meta">
                  {" "}
                  {article.reading_time && (
                    <span>
                      {article.reading_time} Minute
                      {article.reading_time > 1 && <span>n</span>} Lesezeit
                    </span>
                  )}
                </div>
                <p
                  className="LatestText"
                  dangerouslySetInnerHTML={{
                    __html: article.excerpt,
                  }}
                ></p>

                <Link
                  draggable="false"
                  onClick={() => setLoading(true)}
                  className="button"
                  to={`/article/${article.id}`}
                >
                  Mehr erfahren
                </Link>
              </article>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Home;
