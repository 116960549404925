import React from "react";
import Header from "../components/Header";

function Unkown({ loading, setLoading }) {
    React.useEffect(() => {
        setLoading(false);
    }, []);
    return (
        <>
            <Header
                loading={loading}
                setLoading={setLoading}
                bigHeader={false}
            />

            <div className="App container">
                <h1 className="errorbig">404</h1>
                <h2 className="error">
                    Diese Seite wurde nicht gefunden oder ist deaktiviert.
                </h2>
            </div>
        </>
    );
}

export default Unkown;
