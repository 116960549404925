import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Home from "./pages/Home";
import Fahrzeuge from "./pages/Fahrzeuge";
import Termine from "./pages/Termine";
import Impressum from "./pages/Impressum";
import Article from "./pages/Article";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Unkown from "./pages/Unkown";
import Geschichte from "./pages/Geschichte";

function Routes() {
    const [loading, setLoading] = React.useState(false);

    return (
        <>
            <Router>
                <Switch>
                    <Route
                        path="/article/:id"
                        render={(props) => (
                            <Article
                                {...props}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        )}
                    ></Route>
                    <Route
                        path="/impressum"
                        render={(props) => (
                            <Impressum
                                {...props}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        )}
                    ></Route>
                    <Route
                        path="/termine"
                        render={(props) => (
                            <Termine
                                {...props}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        )}
                    ></Route>
                    <Route
                        path="/geschichte"
                        render={(props) => (
                            <Geschichte
                                {...props}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        )}
                    ></Route>
                    <Route
                        path="/fahrzeuge"
                        render={(props) => (
                            <Fahrzeuge
                                {...props}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        )}
                    ></Route>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <Home
                                {...props}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        )}
                    ></Route>
                    <Route
                        path="/"
                        render={(props) => (
                            <Unkown
                                {...props}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        )}
                    ></Route>
                </Switch>
                <Footer />
            </Router>
        </>
    );
}

export default Routes;
