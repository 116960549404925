import React from "react";
import Header from "../components/Header";

function Impressum({ loading, setLoading }) {
    React.useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <>
            <Header
                loading={loading}
                setLoading={setLoading}
                bigHeader={false}
            />
            <div className="App container">
                <h1 className="Title">Impressum:</h1>
                <div className="impressum">
                    <h2>Angaben gemäß § 5 TMG:</h2>
                    <p>
                        Markus Weber <br />
                        Moos 15a <br /> 93466 Lederdorn
                    </p>
                    <h2>Kontakt:</h2>
                    <p>Email: /</p>
                    <h2>Haftung für Inhalte:</h2>
                    <p>
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                        eigene Inhalte auf diesen Seiten nach den allgemeinen
                        Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
                        als Diensteanbieter jedoch nicht verpflichtet,
                        übermittelte oder gespeicherte fremde Informationen zu
                        überwachen oder nach Umständen zu forschen, die auf eine
                        rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                        Entfernung oder Sperrung der Nutzung von Informationen
                        nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                        Eine diesbezügliche Haftung ist jedoch erst ab dem
                        Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                        möglich. Bei Bekanntwerden von entsprechenden
                        Rechtsverletzungen werden wir diese Inhalte umgehend
                        entfernen.
                    </p>
                    <h2>Haftung für Links:</h2>
                    <p>
                        Unser Angebot enthält Links zu externen Webseiten
                        Dritter, auf deren Inhalte wir keinen Einfluss haben.
                        Deshalb können wir für diese fremden Inhalte auch keine
                        Gewähr übernehmen. Für die Inhalte der verlinkten Seiten
                        ist stets der jeweilige Anbieter oder Betreiber der
                        Seiten verantwortlich. Die verlinkten Seiten wurden zum
                        Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                        überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                        Verlinkung nicht erkennbar. Eine permanente inhaltliche
                        Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                        Bekanntwerden von Rechtsverletzungen werden wir
                        derartige Links umgehend entfernen.
                    </p>
                    <h2>Urheberrecht:</h2>
                    <p>
                        Die durch die Seitenbetreiber erstellten Inhalte und
                        Werke auf diesen Seiten unterliegen dem deutschen
                        Urheberrecht. Die Vervielfältigung, Bearbeitung,
                        Verbreitung und jede Art der Verwertung außerhalb der
                        Grenzen des Urheberrechtes bedürfen der schriftlichen
                        Zustimmung des jeweiligen Autors bzw. Erstellers.
                        Downloads und Kopien dieser Seite sind nur für den
                        privaten, nicht kommerziellen Gebrauch gestattet. Soweit
                        die Inhalte auf dieser Seite nicht vom Betreiber
                        erstellt wurden, werden die Urheberrechte Dritter
                        beachtet. Insbesondere werden Inhalte Dritter als solche
                        gekennzeichnet. Sollten Sie trotzdem auf eine
                        Urheberrechtsverletzung aufmerksam werden, bitten wir um
                        einen entsprechenden Hinweis. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Inhalte umgehend
                        entfernen.
                    </p>
                    <div className="entry-content" itemprop="articleBody">
                        <h2>Datenschutzerklärung</h2>
                        <h3 id="dsg-general-intro"></h3>
                        <p>
                            Diese Datenschutzerklärung klärt Sie über die Art,
                            den Umfang und Zweck der Verarbeitung von
                            personenbezogenen Daten (nachfolgend kurz „Daten“)
                            innerhalb unseres Onlineangebotes und der mit ihm
                            verbundenen Webseiten, Funktionen und Inhalte sowie
                            externen Onlinepräsenzen, wie z.B. unser Social
                            Media Profile auf (nachfolgend gemeinsam bezeichnet
                            als „Onlineangebot“). Im Hinblick auf die
                            verwendeten Begrifflichkeiten, wie z.B.
                            „Verarbeitung“ oder „Verantwortlicher“ verweisen wir
                            auf die Definitionen im Art. 4 der
                            Datenschutzgrundverordnung (DSGVO).
                            <br />
                            <br />
                        </p>
                        <h3 id="dsg-general-controller">Verantwortlicher</h3>
                        <p>
                            <span className="tsmcontroller">
                                Matthias, Klein
                                <br />
                                Gänsbergweg 4b
                                <br />
                                93466 Chamerau - Lederdorn
                                <br />
                                Deutschland
                                <br />
                                <br />
                                E-Mailadresse:
                                matthias_klein@feuerwehr-lederdorn.de
                                <br />
                                http://feuerwehr-lederdorn.de/impressum
                                <br />
                            </span>
                        </p>
                        <h3 id="dsg-general-datatype">
                            Arten der verarbeiteten Daten:
                        </h3>
                        <p>
                            - Bestandsdaten (z.B., Namen, Adressen).
                            <br />
                            - Kontaktdaten (z.B., E-Mail, Telefonnummern).
                            <br />
                            - Inhaltsdaten (z.B., Texteingaben, Fotografien,
                            Videos).
                            <br />
                            - Nutzungsdaten (z.B., besuchte Webseiten, Interesse
                            an Inhalten, Zugriffszeiten).
                            <br />
                            - Meta-/Kommunikationsdaten (z.B.,
                            Geräte-Informationen, IP-Adressen).
                            <br />
                        </p>
                        <h3 id="dsg-general-datasubjects">
                            Kategorien betroffener Personen
                        </h3>
                        <p>
                            Besucher und Nutzer des Onlineangebotes (Nachfolgend
                            bezeichnen wir die betroffenen Personen
                            zusammenfassend auch als „Nutzer“).
                            <br />
                        </p>
                        <h3 id="dsg-general-purpose">Zweck der Verarbeitung</h3>
                        <p>
                            - Zurverfügungstellung des Onlineangebotes, seiner
                            Funktionen und Inhalte.
                            <br />
                            - Beantwortung von Kontaktanfragen und Kommunikation
                            mit Nutzern.
                            <br />
                            - Sicherheitsmaßnahmen.
                            <br />
                            - Reichweitenmessung/Marketing
                            <br />
                            <span className="tsmcom"></span>
                        </p>
                        <h3 id="dsg-general-terms">
                            Verwendete Begrifflichkeiten{" "}
                        </h3>
                        <p>
                            „Personenbezogene Daten“ sind alle Informationen,
                            die sich auf eine identifizierte oder
                            identifizierbare natürliche Person (im Folgenden
                            „betroffene Person“) beziehen; als identifizierbar
                            wird eine natürliche Person angesehen, die direkt
                            oder indirekt, insbesondere mittels Zuordnung zu
                            einer Kennung wie einem Namen, zu einer Kennnummer,
                            zu Standortdaten, zu einer Online-Kennung (z.B.
                            Cookie) oder zu einem oder mehreren besonderen
                            Merkmalen identifiziert werden kann, die Ausdruck
                            der physischen, physiologischen, genetischen,
                            psychischen, wirtschaftlichen, kulturellen oder
                            sozialen Identität dieser natürlichen Person sind.
                            <br />
                            <br />
                            „Verarbeitung“ ist jeder mit oder ohne Hilfe
                            automatisierter Verfahren ausgeführte Vorgang oder
                            jede solche Vorgangsreihe im Zusammenhang mit
                            personenbezogenen Daten. Der Begriff reicht weit und
                            umfasst praktisch jeden Umgang mit Daten.
                            <br />
                            <br />
                            „Pseudonymisierung“ die Verarbeitung
                            personenbezogener Daten in einer Weise, dass die
                            personenbezogenen Daten ohne Hinzuziehung
                            zusätzlicher Informationen nicht mehr einer
                            spezifischen betroffenen Person zugeordnet werden
                            können, sofern diese zusätzlichen Informationen
                            gesondert aufbewahrt werden und technischen und
                            organisatorischen Maßnahmen unterliegen, die
                            gewährleisten, dass die personenbezogenen Daten
                            nicht einer identifizierten oder identifizierbaren
                            natürlichen Person zugewiesen werden.
                            <br />
                            <br />
                            „Profiling“ jede Art der automatisierten
                            Verarbeitung personenbezogener Daten, die darin
                            besteht, dass diese personenbezogenen Daten
                            verwendet werden, um bestimmte persönliche Aspekte,
                            die sich auf eine natürliche Person beziehen, zu
                            bewerten, insbesondere um Aspekte bezüglich
                            Arbeitsleistung, wirtschaftliche Lage, Gesundheit,
                            persönliche Vorlieben, Interessen, Zuverlässigkeit,
                            Verhalten, Aufenthaltsort oder Ortswechsel dieser
                            natürlichen Person zu analysieren oder
                            vorherzusagen.
                            <br />
                            <br />
                            Als „Verantwortlicher“ wird die natürliche oder
                            juristische Person, Behörde, Einrichtung oder andere
                            Stelle, die allein oder gemeinsam mit anderen über
                            die Zwecke und Mittel der Verarbeitung von
                            personenbezogenen Daten entscheidet, bezeichnet.
                            <br />
                            <br />
                            „Auftragsverarbeiter“ eine natürliche oder
                            juristische Person, Behörde, Einrichtung oder andere
                            Stelle, die personenbezogene Daten im Auftrag des
                            Verantwortlichen verarbeitet.
                            <br />
                        </p>
                        <h3 id="dsg-general-legalbasis">
                            Maßgebliche Rechtsgrundlagen
                        </h3>
                        <p>
                            Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die
                            Rechtsgrundlagen unserer Datenverarbeitungen mit.
                            Sofern die Rechtsgrundlage in der
                            Datenschutzerklärung nicht genannt wird, gilt
                            Folgendes: Die Rechtsgrundlage für die Einholung von
                            Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7
                            DSGVO, die Rechtsgrundlage für die Verarbeitung zur
                            Erfüllung unserer Leistungen und Durchführung
                            vertraglicher Maßnahmen sowie Beantwortung von
                            Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die
                            Rechtsgrundlage für die Verarbeitung zur Erfüllung
                            unserer rechtlichen Verpflichtungen ist Art. 6 Abs.
                            1 lit. c DSGVO, und die Rechtsgrundlage für die
                            Verarbeitung zur Wahrung unserer berechtigten
                            Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den
                            Fall, dass lebenswichtige Interessen der betroffenen
                            Person oder einer anderen natürlichen Person eine
                            Verarbeitung personenbezogener Daten erforderlich
                            machen, dient Art. 6 Abs. 1 lit. d DSGVO als
                            Rechtsgrundlage.
                        </p>
                        <h3 id="dsg-general-securitymeasures">
                            Sicherheitsmaßnahmen
                        </h3>
                        <p>
                            Wir treffen nach Maßgabe des Art. 32 DSGVO unter
                            Berücksichtigung des Stands der Technik, der
                            Implementierungskosten und der Art, des Umfangs, der
                            Umstände und der Zwecke der Verarbeitung sowie der
                            unterschiedlichen Eintrittswahrscheinlichkeit und
                            Schwere des Risikos für die Rechte und Freiheiten
                            natürlicher Personen, geeignete technische und
                            organisatorische Maßnahmen, um ein dem Risiko
                            angemessenes Schutzniveau zu gewährleisten.
                            <br />
                            <br />
                            Zu den Maßnahmen gehören insbesondere die Sicherung
                            der Vertraulichkeit, Integrität und Verfügbarkeit
                            von Daten durch Kontrolle des physischen Zugangs zu
                            den Daten, als auch des sie betreffenden Zugriffs,
                            der Eingabe, Weitergabe, der Sicherung der
                            Verfügbarkeit und ihrer Trennung. Des Weiteren haben
                            wir Verfahren eingerichtet, die eine Wahrnehmung von
                            Betroffenenrechten, Löschung von Daten und Reaktion
                            auf Gefährdung der Daten gewährleisten. Ferner
                            berücksichtigen wir den Schutz personenbezogener
                            Daten bereits bei der Entwicklung, bzw. Auswahl von
                            Hardware, Software sowie Verfahren, entsprechend dem
                            Prinzip des Datenschutzes durch Technikgestaltung
                            und durch datenschutzfreundliche Voreinstellungen
                            (Art. 25 DSGVO).
                            <br />
                        </p>
                        <h3 id="dsg-general-coprocessing">
                            Zusammenarbeit mit Auftragsverarbeitern und Dritten
                        </h3>
                        <p>
                            Sofern wir im Rahmen unserer Verarbeitung Daten
                            gegenüber anderen Personen und Unternehmen
                            (Auftragsverarbeitern oder Dritten) offenbaren, sie
                            an diese übermitteln oder ihnen sonst Zugriff auf
                            die Daten gewähren, erfolgt dies nur auf Grundlage
                            einer gesetzlichen Erlaubnis (z.B. wenn eine
                            Übermittlung der Daten an Dritte, wie an
                            Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b
                            DSGVO zur Vertragserfüllung erforderlich ist), Sie
                            eingewilligt haben, eine rechtliche Verpflichtung
                            dies vorsieht oder auf Grundlage unserer
                            berechtigten Interessen (z.B. beim Einsatz von
                            Beauftragten, Webhostern, etc.). <br />
                            <br />
                            Sofern wir Dritte mit der Verarbeitung von Daten auf
                            Grundlage eines sog.
                            „Auftragsverarbeitungsvertrages“ beauftragen,
                            geschieht dies auf Grundlage des Art. 28 DSGVO.
                        </p>
                        <h3 id="dsg-general-thirdparty">
                            Übermittlungen in Drittländer
                        </h3>
                        <p>
                            Sofern wir Daten in einem Drittland (d.h. außerhalb
                            der Europäischen Union (EU) oder des Europäischen
                            Wirtschaftsraums (EWR)) verarbeiten oder dies im
                            Rahmen der Inanspruchnahme von Diensten Dritter oder
                            Offenlegung, bzw. Übermittlung von Daten an Dritte
                            geschieht, erfolgt dies nur, wenn es zur Erfüllung
                            unserer (vor)vertraglichen Pflichten, auf Grundlage
                            Ihrer Einwilligung, aufgrund einer rechtlichen
                            Verpflichtung oder auf Grundlage unserer
                            berechtigten Interessen geschieht. Vorbehaltlich
                            gesetzlicher oder vertraglicher Erlaubnisse,
                            verarbeiten oder lassen wir die Daten in einem
                            Drittland nur beim Vorliegen der besonderen
                            Voraussetzungen der Art. 44 ff. DSGVO verarbeiten.
                            D.h. die Verarbeitung erfolgt z.B. auf Grundlage
                            besonderer Garantien, wie der offiziell anerkannten
                            Feststellung eines der EU entsprechenden
                            Datenschutzniveaus (z.B. für die USA durch das
                            „Privacy Shield“) oder Beachtung offiziell
                            anerkannter spezieller vertraglicher Verpflichtungen
                            (so genannte „Standardvertragsklauseln“).
                        </p>
                        <h3 id="dsg-general-rightssubject">
                            Rechte der betroffenen Personen
                        </h3>
                        <p>
                            Sie haben das Recht, eine Bestätigung darüber zu
                            verlangen, ob betreffende Daten verarbeitet werden
                            und auf Auskunft über diese Daten sowie auf weitere
                            Informationen und Kopie der Daten entsprechend Art.
                            15 DSGVO.
                            <br />
                            <br />
                            Sie haben entsprechend. Art. 16 DSGVO das Recht, die
                            Vervollständigung der Sie betreffenden Daten oder
                            die Berichtigung der Sie betreffenden unrichtigen
                            Daten zu verlangen.
                            <br />
                            <br />
                            Sie haben nach Maßgabe des Art. 17 DSGVO das Recht
                            zu verlangen, dass betreffende Daten unverzüglich
                            gelöscht werden, bzw. alternativ nach Maßgabe des
                            Art. 18 DSGVO eine Einschränkung der Verarbeitung
                            der Daten zu verlangen.
                            <br />
                            <br />
                            Sie haben das Recht zu verlangen, dass die Sie
                            betreffenden Daten, die Sie uns bereitgestellt haben
                            nach Maßgabe des Art. 20 DSGVO zu erhalten und deren
                            Übermittlung an andere Verantwortliche zu fordern.{" "}
                            <br />
                            <br />
                            Sie haben ferner gem. Art. 77 DSGVO das Recht, eine
                            Beschwerde bei der zuständigen Aufsichtsbehörde
                            einzureichen.
                        </p>
                        <h3 id="dsg-general-revokeconsent">Widerrufsrecht</h3>
                        <p>
                            Sie haben das Recht, erteilte Einwilligungen gem.
                            Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu
                            widerrufen
                        </p>
                        <h3 id="dsg-general-object">Widerspruchsrecht</h3>
                        <p>
                            Sie können der künftigen Verarbeitung der Sie
                            betreffenden Daten nach Maßgabe des Art. 21 DSGVO
                            jederzeit widersprechen. Der Widerspruch kann
                            insbesondere gegen die Verarbeitung für Zwecke der
                            Direktwerbung erfolgen.
                        </p>
                        <h3 id="dsg-general-cookies">
                            Cookies und Widerspruchsrecht bei Direktwerbung
                        </h3>
                        <p>
                            Als „Cookies“ werden kleine Dateien bezeichnet, die
                            auf Rechnern der Nutzer gespeichert werden.
                            Innerhalb der Cookies können unterschiedliche
                            Angaben gespeichert werden. Ein Cookie dient primär
                            dazu, die Angaben zu einem Nutzer (bzw. dem Gerät
                            auf dem das Cookie gespeichert ist) während oder
                            auch nach seinem Besuch innerhalb eines
                            Onlineangebotes zu speichern. Als temporäre Cookies,
                            bzw. „Session-Cookies“ oder „transiente Cookies“,
                            werden Cookies bezeichnet, die gelöscht werden,
                            nachdem ein Nutzer ein Onlineangebot verlässt und
                            seinen Browser schließt. In einem solchen Cookie
                            kann z.B. der Inhalt eines Warenkorbs in einem
                            Onlineshop oder ein Login-Status gespeichert werden.
                            Als „permanent“ oder „persistent“ werden Cookies
                            bezeichnet, die auch nach dem Schließen des Browsers
                            gespeichert bleiben. So kann z.B. der Login-Status
                            gespeichert werden, wenn die Nutzer diese nach
                            mehreren Tagen aufsuchen. Ebenso können in einem
                            solchen Cookie die Interessen der Nutzer gespeichert
                            werden, die für Reichweitenmessung oder
                            Marketingzwecke verwendet werden. Als
                            „Third-Party-Cookie“ werden Cookies bezeichnet, die
                            von anderen Anbietern als dem Verantwortlichen, der
                            das Onlineangebot betreibt, angeboten werden
                            (andernfalls, wenn es nur dessen Cookies sind
                            spricht man von „First-Party Cookies“).
                            <br />
                            <br />
                            Wir können temporäre und permanente Cookies
                            einsetzen und klären hierüber im Rahmen unserer
                            Datenschutzerklärung auf.
                            <br />
                            <br />
                            Falls die Nutzer nicht möchten, dass Cookies auf
                            ihrem Rechner gespeichert werden, werden sie gebeten
                            die entsprechende Option in den Systemeinstellungen
                            ihres Browsers zu deaktivieren. Gespeicherte Cookies
                            können in den Systemeinstellungen des Browsers
                            gelöscht werden. Der Ausschluss von Cookies kann zu
                            Funktionseinschränkungen dieses Onlineangebotes
                            führen.
                            <br />
                            <br />
                            Ein genereller Widerspruch gegen den Einsatz der zu
                            Zwecken des Onlinemarketing eingesetzten Cookies
                            kann bei einer Vielzahl der Dienste, vor allem im
                            Fall des Trackings, über die US-amerikanische Seite{" "}
                            <a href="http://www.aboutads.info/choices/">
                                http://www.aboutads.info/choices/
                            </a>{" "}
                            oder die EU-Seite{" "}
                            <a href="http://www.youronlinechoices.com/">
                                http://www.youronlinechoices.com/
                            </a>{" "}
                            erklärt werden. Des Weiteren kann die Speicherung
                            von Cookies mittels deren Abschaltung in den
                            Einstellungen des Browsers erreicht werden. Bitte
                            beachten Sie, dass dann gegebenenfalls nicht alle
                            Funktionen dieses Onlineangebotes genutzt werden
                            können.
                        </p>
                        <h3 id="dsg-general-erasure">Löschung von Daten</h3>
                        <p>
                            Die von uns verarbeiteten Daten werden nach Maßgabe
                            der Art. 17 und 18 DSGVO gelöscht oder in ihrer
                            Verarbeitung eingeschränkt. Sofern nicht im Rahmen
                            dieser Datenschutzerklärung ausdrücklich angegeben,
                            werden die bei uns gespeicherten Daten gelöscht,
                            sobald sie für ihre Zweckbestimmung nicht mehr
                            erforderlich sind und der Löschung keine
                            gesetzlichen Aufbewahrungspflichten entgegenstehen.
                            Sofern die Daten nicht gelöscht werden, weil sie für
                            andere und gesetzlich zulässige Zwecke erforderlich
                            sind, wird deren Verarbeitung eingeschränkt. D.h.
                            die Daten werden gesperrt und nicht für andere
                            Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
                            handels- oder steuerrechtlichen Gründen aufbewahrt
                            werden müssen.
                            <br />
                            <br />
                            Nach gesetzlichen Vorgaben in Deutschland, erfolgt
                            die Aufbewahrung insbesondere für 10 Jahre gemäß §§
                            147 Abs. 1 AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB
                            (Bücher, Aufzeichnungen, Lageberichte,
                            Buchungsbelege, Handelsbücher, für Besteuerung
                            relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257
                            Abs. 1 Nr. 2 und 3, Abs. 4 HGB (Handelsbriefe).{" "}
                            <br />
                            <br />
                            Nach gesetzlichen Vorgaben in Österreich erfolgt die
                            Aufbewahrung insbesondere für 7 J gemäß § 132 Abs. 1
                            BAO (Buchhaltungsunterlagen, Belege/Rechnungen,
                            Konten, Belege, Geschäftspapiere, Aufstellung der
                            Einnahmen und Ausgaben, etc.), für 22 Jahre im
                            Zusammenhang mit Grundstücken und für 10 Jahre bei
                            Unterlagen im Zusammenhang mit elektronisch
                            erbrachten Leistungen, Telekommunikations-,
                            Rundfunk- und Fernsehleistungen, die an
                            Nichtunternehmer in EU-Mitgliedstaaten erbracht
                            werden und für die der Mini-One-Stop-Shop (MOSS) in
                            Anspruch genommen wird.
                        </p>
                        <p></p>
                        <h3 id="dsg-organisation">
                            {" "}
                            Erbringung unserer satzungs- und geschäftsgemäßen
                            Leistungen
                        </h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir verarbeiten die Daten unserer Mitglieder,
                                Unterstützer, Interessenten, Kunden oder
                                sonstiger Personen entsprechend Art. 6 Abs. 1
                                lit. b. DSGVO, sofern wir ihnen gegenüber
                                vertragliche Leistungen anbieten oder im Rahmen
                                bestehender geschäftlicher Beziehung, z.B.
                                gegenüber Mitgliedern, tätig werden oder selbst
                                Empfänger von Leistungen und Zuwendungen sind.
                                Im Übrigen verarbeiten wir die Daten betroffener
                                Personen gem. Art. 6 Abs. 1 lit. f. DSGVO auf
                                Grundlage unserer berechtigten Interessen, z.B.
                                wenn es sich um administrative Aufgaben oder
                                Öffentlichkeitsarbeit handelt.
                                <br />
                                <br />
                                Die hierbei verarbeiteten Daten, die Art, der
                                Umfang und der Zweck und die Erforderlichkeit
                                ihrer Verarbeitung bestimmen sich nach dem
                                zugrundeliegenden Vertragsverhältnis. Dazu
                                gehören grundsätzlich Bestands- und Stammdaten
                                der Personen (z.B., Name, Adresse, etc.), als
                                auch die Kontaktdaten (z.B., E-Mailadresse,
                                Telefon, etc.), die Vertragsdaten (z.B., in
                                Anspruch genommene Leistungen, mitgeteilte
                                Inhalte und Informationen, Namen von
                                Kontaktpersonen) und sofern wir
                                zahlungspflichtige Leistungen oder Produkte
                                anbieten, Zahlungsdaten (z.B., Bankverbindung,
                                Zahlungshistorie, etc.).
                                <br />
                                <br />
                                Wir löschen Daten, die zur Erbringung unserer
                                satzungs- und geschäftsmäßigen Zwecke nicht mehr
                                erforderlich sind. Dies bestimmt sich
                                entsprechend der jeweiligen Aufgaben und
                                vertraglichen Beziehungen. Im Fall
                                geschäftlicher Verarbeitung bewahren wir die
                                Daten so lange auf, wie sie zur
                                Geschäftsabwicklung, als auch im Hinblick auf
                                etwaige Gewährleistungs- oder Haftungspflichten
                                relevant sein können. Die Erforderlichkeit der
                                Aufbewahrung der Daten wird alle drei Jahre
                                überprüft; im Übrigen gelten die gesetzlichen
                                Aufbewahrungspflichten.
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-registration">Registrierfunktion</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Nutzer können ein Nutzerkonto anlegen. Im Rahmen
                                der Registrierung werden die erforderlichen
                                Pflichtangaben den Nutzern mitgeteilt und auf
                                Grundlage des Art. 6 Abs. 1 lit. b DSGVO zu
                                Zwecken der Bereitstellung des Nutzerkontos
                                verarbeitet. Zu den verarbeiteten Daten gehören
                                insbesondere die Login-Informationen (Name,
                                Passwort sowie eine E-Mailadresse). Die im
                                Rahmen der Registrierung eingegebenen Daten
                                werden für die Zwecke der Nutzung des
                                Nutzerkontos und dessen Zwecks verwendet. <br />
                                <br />
                                Die Nutzer können über Informationen, die für
                                deren Nutzerkonto relevant sind, wie z.B.
                                technische Änderungen, per E-Mail informiert
                                werden. Wenn Nutzer ihr Nutzerkonto gekündigt
                                haben, werden deren Daten im Hinblick auf das
                                Nutzerkonto, vorbehaltlich einer gesetzlichen
                                Aufbewahrungspflicht, gelöscht. Es obliegt den
                                Nutzern, ihre Daten bei erfolgter Kündigung vor
                                dem Vertragsende zu sichern. Wir sind
                                berechtigt, sämtliche während der Vertragsdauer
                                gespeicherten Daten des Nutzers
                                unwiederbringlich zu löschen.
                                <br />
                                <br />
                                Im Rahmen der Inanspruchnahme unserer
                                Registrierungs- und Anmeldefunktionen sowie der
                                Nutzung des Nutzerkontos, speichern wird die
                                IP-Adresse und den Zeitpunkt der jeweiligen
                                Nutzerhandlung. Die Speicherung erfolgt auf
                                Grundlage unserer berechtigten Interessen, als
                                auch der Nutzer an Schutz vor Missbrauch und
                                sonstiger unbefugter Nutzung. Eine Weitergabe
                                dieser Daten an Dritte erfolgt grundsätzlich
                                nicht, außer sie ist zur Verfolgung unserer
                                Ansprüche erforderlich oder es besteht hierzu
                                besteht eine gesetzliche Verpflichtung gem. Art.
                                6 Abs. 1 lit. c DSGVO. Die IP-Adressen werden
                                spätestens nach 7 Tagen anonymisiert oder
                                gelöscht.
                                <br />
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-disqus">DISQUS-Kommentarfunktion</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir setzen auf Grundlage unserer berechtigten
                                Interessen an einer effizienten, sicheren und
                                nutzerfreundlichen Kommentarverwaltung gem. Art.
                                6 Abs. 1 lit. f. DSGVO den Kommentardienst
                                DISQUS, angeboten von der DISQUS, Inc., 301
                                Howard St, Floor 3 San Francisco, California-
                                94105, USA, ein. DISQUS ist unter dem
                                Privacy-Shield-Abkommen zertifiziert und bietet
                                hierdurch eine Garantie, das europäische
                                Datenschutzrecht einzuhalten:{" "}
                                <a
                                    target="_blank"
                                    href="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active"
                                >
                                    https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active
                                </a>
                                .<br />
                                <br />
                                Zur Nutzung der DISQUS Kommentarfunktion können
                                Nutzer sich über ein eigenes DISQUS-Nutzer-Konto
                                oder einen bestehende Social-Media-Konten (z.B.
                                OpenID, Facebook, Twitter oder Google) anmelden.
                                Hierbei werden die Anmeldedaten der Nutzer durch
                                DISQS von den Plattformen bezogen. Es ist
                                ebenfalls möglich, die DISQUS-Kommentarfunktion
                                als Gast, ohne Erstellung oder Verwendung
                                Nutzerkontos bei DISQUS oder einem der
                                angegebenen Social-Media-Anbieter, zu nutzen.
                                <br />
                                <br />
                                Wir betten lediglich DISQUS mit seinen
                                Funktionen in unsere Website ein, wobei wir auf
                                die Kommentare der Nutzer Einfluss nehmen
                                können. Die Nutzer treten jedoch in eine
                                unmittelbare Vertragsbeziehung mit DISQUS, in
                                deren Rahmen DISQS die Kommentare der Nutzer
                                verarbeitet und ein Ansprechpartner für etwaige
                                Löschung der Daten der Nutzer ist. Wir verweisen
                                hierbei auf die Datenschutzerklärung von DISQUS:{" "}
                                <a
                                    target="_blank"
                                    href="https://help.disqus.com/terms-and-policies/disqus-privacy-policy"
                                >
                                    https://help.disqus.com/terms-and-policies/disqus-privacy-policy
                                </a>{" "}
                                und weisen die Nutzer ebenfalls darauf hin, dass
                                sie davon ausgehen können, dass DISQUS neben dem
                                Kommentarinhalt auch deren IP-Adresse und den
                                Zeitpunkt des Kommentars speichert sowie Cookies
                                auf den Rechnern der Nutzer speichert und zur
                                Darstellung von Werbung nutzen kann. Nutzer
                                können jedoch der Verarbeitung ihrer Daten
                                zwecks Darstellung von Anzeigen widersprechen:{" "}
                                <a
                                    target="_blank"
                                    href="https://disqus.com/data-sharing-settings/"
                                >
                                    https://disqus.com/data-sharing-settings
                                </a>
                                . <br />
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-comments">Kommentare und Beiträge</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wenn Nutzer Kommentare oder sonstige Beiträge
                                hinterlassen, können ihre IP-Adressen auf
                                Grundlage unserer berechtigten Interessen im
                                Sinne des Art. 6 Abs. 1 lit. f. DSGVO für 7 Tage
                                gespeichert werden. Das erfolgt zu unserer
                                Sicherheit, falls jemand in Kommentaren und
                                Beiträgen widerrechtliche Inhalte hinterlässt
                                (Beleidigungen, verbotene politische Propaganda,
                                etc.). In diesem Fall können wir selbst für den
                                Kommentar oder Beitrag belangt werden und sind
                                daher an der Identität des Verfassers
                                interessiert.
                                <br />
                                <br />
                                Des Weiteren behalten wir uns vor, auf Grundlage
                                unserer berechtigten Interessen gem. Art. 6 Abs.
                                1 lit. f. DSGVO, die Angaben der Nutzer zwecks
                                Spamerkennung zu verarbeiten.
                                <br />
                                <br />
                                Die im Rahmen der Kommentare und Beiträge
                                angegebenen Daten, werden von uns bis zum
                                Widerspruch der Nutzer dauerhaft gespeichert.
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-musicpodcast-soundcloud">Soundcloud</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Unsere Podcasts werden auf der Plattform
                                „Soundcloud“, angeboten von SoundCloud Limited,
                                Rheinsberger Str. 76/77, 10115 Berlin,
                                Deutschland gespeichert und werden von dieser
                                aus Platform wiedergegeben.
                                <br />
                                <br />
                                Zu diesem Zweck binden wir sog.
                                Soundcloud-Widgets in unsere Website ein. Dabei
                                handelt es sich um Abspielsoftware, mit der
                                Nutzer die Podcasts abspielen können. Hierbei
                                kann Soundcloud messen, welche Podcasts in
                                welchem Umfang gehört werden und diese
                                Information pseudonym für statistische und
                                betriebswirtschaftliche Zwecke verarbeiten.
                                Hierzu können Cookies in den Browsern der Nuzer
                                gespeichert und zwecks Bildung von
                                Nutzerprofilen, z.B. für Zwecke der Ausgabee von
                                Anzeigen, die den potentiellen Interessen der
                                Nutzer entsprechen, verarbeitet werden. Im Fall
                                von Nutzern, die bei Soundcloud registriert
                                sind, kann Soundcloud die Hörinformationen deren
                                Profilen zuordnen.
                                <br />
                                <br />
                                Die Nutzung erfolgt auf Grundlage unserer
                                berechtigten Interessen, d.h. Interesse an einer
                                sicheren und effizienten Bereitstellung, Analyse
                                sowie Optimierung unseres Audioangebotes gem.
                                Art. 6 Abs. 1 lit. f. DSGVO. <br />
                                <br />
                                Weitere Informationen und
                                Widerspruchsmöglichkeiten finden sich in der
                                Datenschutzerklärung von Soundcloud:{" "}
                                <a
                                    target="_blank"
                                    href="https://soundcloud.com/pages/privacy"
                                >
                                    https://soundcloud.com/pages/privacy
                                </a>
                                .
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-contact">Kontaktaufnahme</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Bei der Kontaktaufnahme mit uns (z.B. per
                                Kontaktformular, E-Mail, Telefon oder via
                                sozialer Medien) werden die Angaben des Nutzers
                                zur Bearbeitung der Kontaktanfrage und deren
                                Abwicklung gem. Art. 6 Abs. 1 lit. b) DSGVO
                                verarbeitet. Die Angaben der Nutzer können in
                                einem Customer-Relationship-Management System
                                ("CRM System") oder vergleichbarer
                                Anfragenorganisation gespeichert werden.
                                <br />
                                <br />
                                Wir löschen die Anfragen, sofern diese nicht
                                mehr erforderlich sind. Wir überprüfen die
                                Erforderlichkeit alle zwei Jahre; Ferner gelten
                                die gesetzlichen Archivierungspflichten.
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-hostingprovider">
                            Hosting und E-Mail-Versand
                        </h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Die von uns in Anspruch genommenen
                                Hosting-Leistungen dienen der
                                Zurverfügungstellung der folgenden Leistungen:
                                Infrastruktur- und Plattformdienstleistungen,
                                Rechenkapazität, Speicherplatz und
                                Datenbankdienste, E-Mail-Versand,
                                Sicherheitsleistungen sowie technische
                                Wartungsleistungen, die wir zum Zwecke des
                                Betriebs dieses Onlineangebotes einsetzen.{" "}
                                <br />
                                <br />
                                Hierbei verarbeiten wir, bzw. unser
                                Hostinganbieter Bestandsdaten, Kontaktdaten,
                                Inhaltsdaten, Vertragsdaten, Nutzungsdaten,
                                Meta- und Kommunikationsdaten von Kunden,
                                Interessenten und Besuchern dieses
                                Onlineangebotes auf Grundlage unserer
                                berechtigten Interessen an einer effizienten und
                                sicheren Zurverfügungstellung dieses
                                Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO
                                i.V.m. Art. 28 DSGVO (Abschluss
                                Auftragsverarbeitungsvertrag).
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-ga-googleanalytics">Google Analytics</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir setzen auf Grundlage unserer berechtigten
                                Interessen (d.h. Interesse an der Analyse,
                                Optimierung und wirtschaftlichem Betrieb unseres
                                Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
                                f. DSGVO) Google Analytics, einen
                                Webanalysedienst der Google LLC („Google“) ein.
                                Google verwendet Cookies. Die durch das Cookie
                                erzeugten Informationen über Benutzung des
                                Onlineangebotes durch die Nutzer werden in der
                                Regel an einen Server von Google in den USA
                                übertragen und dort gespeichert.
                                <br />
                                <br />
                                Google ist unter dem Privacy-Shield-Abkommen
                                zertifiziert und bietet hierdurch eine Garantie,
                                das europäische Datenschutzrecht einzuhalten (
                                <a
                                    target="_blank"
                                    href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                                >
                                    https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                                </a>
                                ).
                                <br />
                                <br />
                                Google wird diese Informationen in unserem
                                Auftrag benutzen, um die Nutzung unseres
                                Onlineangebotes durch die Nutzer auszuwerten, um
                                Reports über die Aktivitäten innerhalb dieses
                                Onlineangebotes zusammenzustellen und um
                                weitere, mit der Nutzung dieses Onlineangebotes
                                und der Internetnutzung verbundene
                                Dienstleistungen, uns gegenüber zu erbringen.
                                Dabei können aus den verarbeiteten Daten
                                pseudonyme Nutzungsprofile der Nutzer erstellt
                                werden.
                                <br />
                                <br />
                                Wir setzen Google Analytics nur mit aktivierter
                                IP-Anonymisierung ein. Das bedeutet, die
                                IP-Adresse der Nutzer wird von Google innerhalb
                                von Mitgliedstaaten der Europäischen Union oder
                                in anderen Vertragsstaaten des Abkommens über
                                den Europäischen Wirtschaftsraum gekürzt. Nur in
                                Ausnahmefällen wird die volle IP-Adresse an
                                einen Server von Google in den USA übertragen
                                und dort gekürzt.
                                <br />
                                <br />
                                Die von dem Browser des Nutzers übermittelte
                                IP-Adresse wird nicht mit anderen Daten von
                                Google zusammengeführt. Die Nutzer können die
                                Speicherung der Cookies durch eine entsprechende
                                Einstellung ihrer Browser-Software verhindern;
                                die Nutzer können darüber hinaus die Erfassung
                                der durch das Cookie erzeugten und auf ihre
                                Nutzung des Onlineangebotes bezogenen Daten an
                                Google sowie die Verarbeitung dieser Daten durch
                                Google verhindern, indem sie das unter folgendem
                                Link verfügbare Browser-Plugin herunterladen und
                                installieren:&nbsp;
                                <a
                                    target="_blank"
                                    href="http://tools.google.com/dlpage/gaoptout?hl=de"
                                >
                                    http://tools.google.com/dlpage/gaoptout?hl=de
                                </a>
                                .<br />
                                <br />
                                Weitere Informationen zur Datennutzung durch
                                Google, Einstellungs- und
                                Widerspruchsmöglichkeiten, erfahren Sie in der
                                Datenschutzerklärung von Google (
                                <a
                                    target="_blank"
                                    href="https://policies.google.com/technologies/ads"
                                >
                                    https://policies.google.com/technologies/ads
                                </a>
                                ) sowie in den Einstellungen für die Darstellung
                                von Werbeeinblendungen durch Google{" "}
                                <a
                                    target="_blank"
                                    href="https://adssettings.google.com/authenticated"
                                >
                                    (https://adssettings.google.com/authenticated
                                </a>
                                ).
                                <br />
                                <br />
                                Die personenbezogenen Daten der Nutzer werden
                                nach 14 Monaten gelöscht oder anonymisiert.
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-ga-universal">
                            Google Universal Analytics
                        </h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir setzen Google Analytics in der Ausgestaltung
                                als „
                                <a
                                    target="_blank"
                                    href="https://support.google.com/analytics/answer/2790010?hl=de&amp;ref_topic=6010376"
                                >
                                    Universal-Analytics
                                </a>
                                “ ein. „Universal Analytics“ bezeichnet ein
                                Verfahren von Google Analytics, bei dem die
                                Nutzeranalyse auf Grundlage einer pseudonymen
                                Nutzer-ID erfolgt und damit ein pseudonymes
                                Profil des Nutzers mit Informationen aus der
                                Nutzung verschiedener Geräten erstellt wird
                                (sog. „Cross-Device-Tracking“).
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-ga-audiences">
                            Zielgruppenbildung mit Google Analytics
                        </h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir setzen Google Analytics ein, um die durch
                                innerhalb von Werbediensten Googles und seiner
                                Partner geschalteten Anzeigen, nur solchen
                                Nutzern anzuzeigen, die auch ein Interesse an
                                unserem Onlineangebot gezeigt haben oder die
                                bestimmte Merkmale (z.B. Interessen an
                                bestimmten Themen oder Produkten, die anhand der
                                besuchten Webseiten bestimmt werden) aufweisen,
                                die wir an Google übermitteln (sog.
                                „Remarketing-“, bzw.
                                „Google-Analytics-Audiences“). Mit Hilfe der
                                Remarketing Audiences möchten wir auch
                                sicherstellen, dass unsere Anzeigen dem
                                potentiellen Interesse der Nutzer entsprechen.
                                <br />
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-socialmedia">
                            Onlinepräsenzen in sozialen Medien
                        </h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir unterhalten Onlinepräsenzen innerhalb
                                sozialer Netzwerke und Plattformen, um mit den
                                dort aktiven Kunden, Interessenten und Nutzern
                                kommunizieren und sie dort über unsere
                                Leistungen informieren zu können. Beim Aufruf
                                der jeweiligen Netzwerke und Plattformen gelten
                                die Geschäftsbedingungen und die
                                Datenverarbeitungsrichtlinien deren jeweiligen
                                Betreiber. <br />
                                <br />
                                Soweit nicht anders im Rahmen unserer
                                Datenschutzerklärung angegeben, verarbeiten wir
                                die Daten der Nutzer sofern diese mit uns
                                innerhalb der sozialen Netzwerke und Plattformen
                                kommunizieren, z.B. Beiträge auf unseren
                                Onlinepräsenzen verfassen oder uns Nachrichten
                                zusenden.
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-thirdparty-einleitung">
                            Einbindung von Diensten und Inhalten Dritter
                        </h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir setzen innerhalb unseres Onlineangebotes auf
                                Grundlage unserer berechtigten Interessen (d.h.
                                Interesse an der Analyse, Optimierung und
                                wirtschaftlichem Betrieb unseres Onlineangebotes
                                im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
                                Inhalts- oder Serviceangebote von Drittanbietern
                                ein, um deren Inhalte und Services, wie z.B.
                                Videos oder Schriftarten einzubinden
                                (nachfolgend einheitlich bezeichnet als
                                “Inhalte”). <br />
                                <br />
                                Dies setzt immer voraus, dass die Drittanbieter
                                dieser Inhalte, die IP-Adresse der Nutzer
                                wahrnehmen, da sie ohne die IP-Adresse die
                                Inhalte nicht an deren Browser senden könnten.
                                Die IP-Adresse ist damit für die Darstellung
                                dieser Inhalte erforderlich. Wir bemühen uns nur
                                solche Inhalte zu verwenden, deren jeweilige
                                Anbieter die IP-Adresse lediglich zur
                                Auslieferung der Inhalte verwenden.
                                Drittanbieter können ferner so genannte
                                Pixel-Tags (unsichtbare Grafiken, auch als "Web
                                Beacons" bezeichnet) für statistische oder
                                Marketingzwecke verwenden. Durch die
                                "Pixel-Tags" können Informationen, wie der
                                Besucherverkehr auf den Seiten dieser Website
                                ausgewertet werden. Die pseudonymen
                                Informationen können ferner in Cookies auf dem
                                Gerät der Nutzer gespeichert werden und unter
                                anderem technische Informationen zum Browser und
                                Betriebssystem, verweisende Webseiten,
                                Besuchszeit sowie weitere Angaben zur Nutzung
                                unseres Onlineangebotes enthalten, als auch mit
                                solchen Informationen aus anderen Quellen
                                verbunden werden.
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-thirdparty-youtube">Youtube</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir binden die Videos der Plattform “YouTube”
                                des Anbieters Google LLC, 1600 Amphitheatre
                                Parkway, Mountain View, CA 94043, USA, ein.
                                Datenschutzerklärung:{" "}
                                <a
                                    target="_blank"
                                    href="https://www.google.com/policies/privacy/"
                                >
                                    https://www.google.com/policies/privacy/
                                </a>
                                , Opt-Out:{" "}
                                <a
                                    target="_blank"
                                    href="https://adssettings.google.com/authenticated"
                                >
                                    https://adssettings.google.com/authenticated
                                </a>
                                .
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-thirdparty-googlefonts">Google Fonts</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir binden die Schriftarten ("Google Fonts") des
                                Anbieters Google LLC, 1600 Amphitheatre Parkway,
                                Mountain View, CA 94043, USA, ein.
                                Datenschutzerklärung:{" "}
                                <a
                                    target="_blank"
                                    href="https://www.google.com/policies/privacy/"
                                >
                                    https://www.google.com/policies/privacy/
                                </a>
                                , Opt-Out:{" "}
                                <a
                                    target="_blank"
                                    href="https://adssettings.google.com/authenticated"
                                >
                                    https://adssettings.google.com/authenticated
                                </a>
                                .
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-thirdparty-googlerecaptcha">
                            Google ReCaptcha
                        </h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir binden die Funktion zur Erkennung von Bots,
                                z.B. bei Eingaben in Onlineformularen
                                ("ReCaptcha") des Anbieters Google LLC, 1600
                                Amphitheatre Parkway, Mountain View, CA 94043,
                                USA, ein. Datenschutzerklärung:{" "}
                                <a
                                    target="_blank"
                                    href="https://www.google.com/policies/privacy/"
                                >
                                    https://www.google.com/policies/privacy/
                                </a>
                                , Opt-Out:{" "}
                                <a
                                    target="_blank"
                                    href="https://adssettings.google.com/authenticated"
                                >
                                    https://adssettings.google.com/authenticated
                                </a>
                                .
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-thirdparty-googlemaps">Google Maps</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir binden die Landkarten des Dienstes “Google
                                Maps” des Anbieters Google LLC, 1600
                                Amphitheatre Parkway, Mountain View, CA 94043,
                                USA, ein. Zu den verarbeiteten Daten können
                                insbesondere IP-Adressen und Standortdaten der
                                Nutzer gehören, die jedoch nicht ohne deren
                                Einwilligung (im Regelfall im Rahmen der
                                Einstellungen ihrer Mobilgeräte vollzogen),
                                erhoben werden. Die Daten können in den USA
                                verarbeitet werden. Datenschutzerklärung:{" "}
                                <a
                                    target="_blank"
                                    href="https://www.google.com/policies/privacy/"
                                >
                                    https://www.google.com/policies/privacy/
                                </a>
                                , Opt-Out:{" "}
                                <a
                                    target="_blank"
                                    href="https://adssettings.google.com/authenticated"
                                >
                                    https://adssettings.google.com/authenticated
                                </a>
                                .
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-thirdparty-openstreetmap">OpenStreetMap</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir binden die Landkarten des Dienstes
                                "OpenStreetMap" ein (
                                <a
                                    target="_blank"
                                    href="https://www.openstreetmap.de"
                                >
                                    https://www.openstreetmap.de
                                </a>
                                ), die auf Grundlage der Open Data Commons Open
                                Database Lizenz (ODbL) durch die OpenStreetMap
                                Foundation (OSMF) angeboten werden.
                                Datenschutzerklärung:{" "}
                                <a
                                    target="_blank"
                                    href="https://wiki.openstreetmap.org/wiki/Privacy_Policy"
                                >
                                    https://wiki.openstreetmap.org/wiki/Privacy_Policy
                                </a>
                                . <br />
                                <br />
                                Nach unserer Kenntnis werden die Daten der
                                Nutzer durch OpenStreetMap ausschließlich zu
                                Zwecken der Darstellung der Kartenfunktionen und
                                Zwischenspeicherung der gewählten Einstellungen
                                verwendet. Zu diesen Daten können insbesondere
                                IP-Adressen und Standortdaten der Nutzer
                                gehören, die jedoch nicht ohne deren
                                Einwilligung (im Regelfall im Rahmen der
                                Einstellungen ihrer Mobilgeräte vollzogen),
                                erhoben werden. <br />
                                <br />
                                Die Daten können in den USA verarbeitet werden.
                                Weitere Informationen können Sie der
                                Datenschutzerklärung von OpenStreetMap
                                entnehmen:{" "}
                                <a
                                    target="_blank"
                                    href="https://wiki.openstreetmap.org/wiki/Privacy_Policy"
                                >
                                    https://wiki.openstreetmap.org/wiki/Privacy_Policy
                                </a>
                                .
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-facebook-plugin">
                            Verwendung von Facebook Social Plugins
                        </h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Wir nutzen auf Grundlage unserer berechtigten
                                Interessen (d.h. Interesse an der Analyse,
                                Optimierung und wirtschaftlichem Betrieb unseres
                                Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
                                f. DSGVO) Social Plugins ("Plugins") des
                                sozialen Netzwerkes facebook.com, welches von
                                der Facebook Ireland Ltd., 4 Grand Canal Square,
                                Grand Canal Harbour, Dublin 2, Irland betrieben
                                wird ("Facebook"). Die Plugins können
                                Interaktionselemente oder Inhalte (z.B. Videos,
                                Grafiken oder Textbeiträge) darstellen und sind
                                an einem der Facebook Logos erkennbar (weißes
                                „f“ auf blauer Kachel, den Begriffen "Like",
                                "Gefällt mir" oder einem „Daumen hoch“-Zeichen)
                                oder sind mit dem Zusatz "Facebook Social
                                Plugin" gekennzeichnet. Die Liste und das
                                Aussehen der Facebook Social Plugins kann hier
                                eingesehen werden:&nbsp;
                                <a
                                    target="_blank"
                                    href="https://developers.facebook.com/docs/plugins/"
                                >
                                    https://developers.facebook.com/docs/plugins/
                                </a>
                                .<br />
                                <br />
                                Facebook ist unter dem Privacy-Shield-Abkommen
                                zertifiziert und bietet hierdurch eine Garantie,
                                das europäische Datenschutzrecht einzuhalten (
                                <a
                                    target="_blank"
                                    href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
                                >
                                    https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
                                </a>
                                ).
                                <br />
                                <br />
                                Wenn ein Nutzer eine Funktion dieses
                                Onlineangebotes aufruft, die ein solches Plugin
                                enthält, baut sein Gerät eine direkte Verbindung
                                mit den Servern von Facebook auf. Der Inhalt des
                                Plugins wird von Facebook direkt an das Gerät
                                des Nutzers übermittelt und von diesem in das
                                Onlineangebot eingebunden. Dabei können aus den
                                verarbeiteten Daten Nutzungsprofile der Nutzer
                                erstellt werden. Wir haben daher keinen Einfluss
                                auf den Umfang der Daten, die Facebook mit Hilfe
                                dieses Plugins erhebt und informiert die Nutzer
                                daher entsprechend unserem Kenntnisstand.
                                <br />
                                <br />
                                Durch die Einbindung der Plugins erhält Facebook
                                die Information, dass ein Nutzer die
                                entsprechende Seite des Onlineangebotes
                                aufgerufen hat. Ist der Nutzer bei Facebook
                                eingeloggt, kann Facebook den Besuch seinem
                                Facebook-Konto zuordnen. Wenn Nutzer mit den
                                Plugins interagieren, zum Beispiel den Like
                                Button betätigen oder einen Kommentar abgeben,
                                wird die entsprechende Information von Ihrem
                                Gerät direkt an Facebook übermittelt und dort
                                gespeichert. Falls ein Nutzer kein Mitglied von
                                Facebook ist, besteht trotzdem die Möglichkeit,
                                dass Facebook seine IP-Adresse in Erfahrung
                                bringt und speichert. Laut Facebook wird in
                                Deutschland nur eine anonymisierte IP-Adresse
                                gespeichert.
                                <br />
                                <br />
                                Zweck und Umfang der Datenerhebung und die
                                weitere Verarbeitung und Nutzung der Daten durch
                                Facebook sowie die diesbezüglichen Rechte und
                                Einstellungsmöglichkeiten zum Schutz der
                                Privatsphäre der Nutzer, können diese den
                                Datenschutzhinweisen von Facebook
                                entnehmen:&nbsp;
                                <a
                                    target="_blank"
                                    href="https://www.facebook.com/about/privacy/"
                                >
                                    https://www.facebook.com/about/privacy/
                                </a>
                                .<br />
                                <br />
                                Wenn ein Nutzer Facebookmitglied ist und nicht
                                möchte, dass Facebook über dieses Onlineangebot
                                Daten über ihn sammelt und mit seinen bei
                                Facebook gespeicherten Mitgliedsdaten verknüpft,
                                muss er sich vor der Nutzung unseres
                                Onlineangebotes bei Facebook ausloggen und seine
                                Cookies löschen. Weitere Einstellungen und
                                Widersprüche zur Nutzung von Daten für
                                Werbezwecke, sind innerhalb der
                                Facebook-Profileinstellungen möglich:&nbsp;
                                <a
                                    target="_blank"
                                    href="https://www.facebook.com/settings?tab=ads"
                                >
                                    https://www.facebook.com/settings?tab=ads
                                </a>{" "}
                                &nbsp;oder über die US-amerikanische Seite&nbsp;
                                <a
                                    target="_blank"
                                    href="http://www.aboutads.info/choices/"
                                >
                                    http://www.aboutads.info/choices/
                                </a>{" "}
                                &nbsp;oder die EU-Seite&nbsp;
                                <a
                                    target="_blank"
                                    href="http://www.youronlinechoices.com/"
                                >
                                    http://www.youronlinechoices.com/
                                </a>
                                . Die Einstellungen erfolgen
                                plattformunabhängig, d.h. sie werden für alle
                                Geräte, wie Desktopcomputer oder mobile Geräte
                                übernommen.
                            </span>
                        </p>
                        <p></p>
                        <h3 id="dsg-thirdparty-googleplus"> Google+</h3>
                        <p></p>
                        <p>
                            <span className="ts-muster-content">
                                Innerhalb unseres Onlineangebotes können
                                Funktionen und Inhalte der Plattform Google+,
                                angeboten durch die Google LLC, 1600
                                Amphitheatre Parkway, Mountain View, CA 94043,
                                USA („Google“), eingebunden werden. Hierzu
                                können z.B. Inhalte wie Bilder, Videos oder
                                Texte und Schaltflächen gehören, mit denen
                                Nutzer Inhalte dieses Onlineangebotes innerhalb
                                von Twitter teilen können. Sofern die Nutzer
                                Mitglieder der Plattform Google+ sind, kann
                                Google den Aufruf der o.g. Inhalte und
                                Funktionen den dortigen Profilen der Nutzer
                                zuordnen.
                                <br />
                                <br />
                                Google ist unter dem Privacy-Shield-Abkommen
                                zertifiziert und bietet hierdurch eine Garantie,
                                das europäische Datenschutzrecht einzuhalten (
                                <a
                                    target="_blank"
                                    href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                                >
                                    https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                                </a>
                                ). Weitere Informationen zur Datennutzung durch
                                Google, Einstellungs- und
                                Widerspruchsmöglichkeiten, erfahren Sie in der
                                Datenschutzerklärung von Google (
                                <a
                                    target="_blank"
                                    href="https://policies.google.com/technologies/ads"
                                >
                                    https://policies.google.com/technologies/ads
                                </a>
                                ) sowie in den Einstellungen für die Darstellung
                                von Werbeeinblendungen durch Google{" "}
                                <a
                                    target="_blank"
                                    href="https://adssettings.google.com/authenticated"
                                >
                                    (https://adssettings.google.com/authenticated
                                </a>
                                ).
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Impressum;
